<template>
  <div
    class="full-height"
  >
    <router-view />

    <v-bottom-navigation
      v-if="$vuetify.breakpoint.mobile"
      grow
      app
      color="accent"
    >
      <v-btn
        :to="{ name: 'projects-list' }"
      >
        <span
          v-translate
          translate-context="Navigation item title"
          style="margin-top: 2px;"
        >
          Projects
        </span>

        <v-icon>
          apartment
        </v-icon>
      </v-btn>

      <v-btn
        :to="{ name: 'user-settings' }"
      >
        <span
          v-translate
          translate-context="Navigation item title"
          style="margin-top: 2px;"
        >
          Account
        </span>

        <v-icon>
          face
        </v-icon>
      </v-btn>

      <v-btn
        v-if="$currentUser.permissions.includes('write_notification_logs')"
        :to="{ name: 'own-notifications' }"
      >
        <v-badge
          :value="unseenLogs.length > 0"
          top
          right
          color="deep-orange"
          :content="unseenLogs.length"
          offset-x="32"
          offset-y="14"
        >
          <div
            class="d-flex flex-column"
          >
            <v-icon>
              announcement
            </v-icon>

            <span
              v-translate
              translate-context="Navigation item title"
              style="margin-top: 2px;"
            >
              Notifications
            </span>
          </div>
        </v-badge>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters({
        unseenLogs: 'notificationLogs/unseenLogs',
      }),
    },
  };
</script>
